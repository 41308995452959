/* eslint-disable @typescript-eslint/no-empty-function */
'use client';
import { Modal } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useParams, usePathname } from 'next/navigation';
import React from 'react';
import { useState } from 'react';

import { toVW } from '@/lib/utils';

import ButtonCore from '@/components/core/Button';
import Link from '@/components/core/Link';

import { useTranslation } from '@/app/i18n/client';
import { fallbackLng } from '@/app/i18n/settings';

const Menus = [
  {
    label: 'footerMobile.label1',
    icon: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5ZM6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15ZM6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16Z'
          fill='#845D00'
        />
      </svg>
    ),
    iconHide: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5ZM6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15ZM6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16Z'
          fill='black'
          fillOpacity='0.45'
        />
      </svg>
    ),
    link: '/?slug=hot',
    paths: '/',
    onClick: () => {
      window.homePage?.setGamePage('hot');
      window.scrollTo(0, 0);
    },
  },
  {
    label: 'footerMobile.label2',
    icon: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5 4.5L11.3111 3.01391C10.7984 2.37306 10.0223 2 9.20156 2H9C7.61929 2 6.5 3.11929 6.5 4.5C6.5 4.67123 6.51722 4.83844 6.55001 5H5.5C3.84315 5 2.5 6.34315 2.5 8V9C2.5 9.8885 2.88625 10.6868 3.5 11.2361V19C3.5 20.6569 4.84315 22 6.5 22H18.5C20.1569 22 21.5 20.6569 21.5 19V11.2361C22.1137 10.6868 22.5 9.8885 22.5 9V8C22.5 6.34315 21.1569 5 19.5 5H18.45C18.4828 4.83844 18.5 4.67123 18.5 4.5C18.5 3.11929 17.3807 2 16 2H15.7984C14.9777 2 14.2016 2.37306 13.6889 3.01391L12.5 4.5ZM14.6612 5H16C16.2761 5 16.5 4.77614 16.5 4.5C16.5 4.22386 16.2761 4 16 4H15.7984C15.5853 4 15.3837 4.09688 15.2506 4.2633L14.6612 5ZM5.5 7C4.94772 7 4.5 7.44772 4.5 8V9C4.5 9.55229 4.94772 10 5.5 10H11.5V7H5.5ZM13.5 7V10H19.5C20.0523 10 20.5 9.55228 20.5 9V8C20.5 7.44771 20.0523 7 19.5 7H13.5ZM8.5 4.5C8.5 4.77614 8.72386 5 9 5H10.3388L9.74939 4.2633C9.61625 4.09688 9.41468 4 9.20156 4H9C8.72386 4 8.5 4.22386 8.5 4.5ZM5.5 12L5.5 19C5.5 19.5523 5.94772 20 6.5 20H11.5V12H5.5ZM13.5 20V12H19.5V19C19.5 19.5523 19.0523 20 18.5 20H13.5Z'
          fill='#845D00'
        />
      </svg>
    ),
    iconHide: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5 4.5L11.3111 3.01391C10.7984 2.37306 10.0223 2 9.20156 2H9C7.61929 2 6.5 3.11929 6.5 4.5C6.5 4.67123 6.51722 4.83844 6.55001 5H5.5C3.84315 5 2.5 6.34315 2.5 8V9C2.5 9.8885 2.88625 10.6868 3.5 11.2361V19C3.5 20.6569 4.84315 22 6.5 22H18.5C20.1569 22 21.5 20.6569 21.5 19V11.2361C22.1137 10.6868 22.5 9.8885 22.5 9V8C22.5 6.34315 21.1569 5 19.5 5H18.45C18.4828 4.83844 18.5 4.67123 18.5 4.5C18.5 3.11929 17.3807 2 16 2H15.7984C14.9777 2 14.2016 2.37306 13.6889 3.01391L12.5 4.5ZM14.6612 5H16C16.2761 5 16.5 4.77614 16.5 4.5C16.5 4.22386 16.2761 4 16 4H15.7984C15.5853 4 15.3837 4.09688 15.2506 4.2633L14.6612 5ZM5.5 7C4.94772 7 4.5 7.44772 4.5 8V9C4.5 9.55229 4.94772 10 5.5 10H11.5V7H5.5ZM13.5 7V10H19.5C20.0523 10 20.5 9.55228 20.5 9V8C20.5 7.44771 20.0523 7 19.5 7H13.5ZM8.5 4.5C8.5 4.77614 8.72386 5 9 5H10.3388L9.74939 4.2633C9.61625 4.09688 9.41468 4 9.20156 4H9C8.72386 4 8.5 4.22386 8.5 4.5ZM5.5 12L5.5 19C5.5 19.5523 5.94772 20 6.5 20H11.5V12H5.5ZM13.5 20V12H19.5V19C19.5 19.5523 19.0523 20 18.5 20H13.5Z'
          fill='black'
          fillOpacity='0.45'
        />
      </svg>
    ),
    link: '/promotion',
    paths: ['/promotion', '/promotion/*'],
    prefetch: true,
  },
  {
    label: 'footerMobile.label3',
    link: '/account/deposit',
    paths: ['/account/deposit', '/account/withdraw'],
  },
  {
    label: 'footerMobile.label4',
    icon: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 9C7.5 8.44772 7.94772 8 8.5 8H16.5C17.0523 8 17.5 8.44772 17.5 9C17.5 9.55228 17.0523 10 16.5 10H8.5C7.94772 10 7.5 9.55228 7.5 9Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 13C7.5 12.4477 7.94772 12 8.5 12H12.5C13.0523 12 13.5 12.4477 13.5 13C13.5 13.5523 13.0523 14 12.5 14H8.5C7.94772 14 7.5 13.5523 7.5 13Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.21963 17.4636C7.57906 17.164 8.03213 17 8.5 17H19.5C20.0523 17 20.5 16.5523 20.5 16V6C20.5 5.44771 20.0523 5 19.5 5H5.5C4.94772 5 4.5 5.44772 4.5 6V19.7299L7.21963 17.4636ZM8.5 19H19.5C21.1569 19 22.5 17.6569 22.5 16V6C22.5 4.34315 21.1569 3 19.5 3H5.5C3.84315 3 2.5 4.34315 2.5 6V19.7299C2.5 21.4256 4.47771 22.3519 5.78037 21.2664L8.5 19Z'
          fill='#845D00'
        />
      </svg>
    ),
    iconHide: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 9C7.5 8.44772 7.94772 8 8.5 8H16.5C17.0523 8 17.5 8.44772 17.5 9C17.5 9.55228 17.0523 10 16.5 10H8.5C7.94772 10 7.5 9.55228 7.5 9Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 13C7.5 12.4477 7.94772 12 8.5 12H12.5C13.0523 12 13.5 12.4477 13.5 13C13.5 13.5523 13.0523 14 12.5 14H8.5C7.94772 14 7.5 13.5523 7.5 13Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.21963 17.4636C7.57906 17.164 8.03213 17 8.5 17H19.5C20.0523 17 20.5 16.5523 20.5 16V6C20.5 5.44771 20.0523 5 19.5 5H5.5C4.94772 5 4.5 5.44772 4.5 6V19.7299L7.21963 17.4636ZM8.5 19H19.5C21.1569 19 22.5 17.6569 22.5 16V6C22.5 4.34315 21.1569 3 19.5 3H5.5C3.84315 3 2.5 4.34315 2.5 6V19.7299C2.5 21.4256 4.47771 22.3519 5.78037 21.2664L8.5 19Z'
          fill='black'
          fillOpacity='0.45'
        />
      </svg>
    ),
    link: '/support',
    paths: ['/support'],
  },
  {
    label: 'footerMobile.label5',
    icon: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5 12C13.6046 12 14.5 11.1046 14.5 10C14.5 8.89543 13.6046 8 12.5 8C11.3954 8 10.5 8.89543 10.5 10C10.5 11.1046 11.3954 12 12.5 12ZM12.5 14C14.7091 14 16.5 12.2091 16.5 10C16.5 7.79086 14.7091 6 12.5 6C10.2909 6 8.5 7.79086 8.5 10C8.5 12.2091 10.2909 14 12.5 14Z'
          fill='#845D00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.0588 19.5488C21.1672 17.7154 22.5 15.0134 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 15.0134 3.83284 17.7154 5.94116 19.5488C7.69693 21.0756 9.99052 22 12.5 22C14.9162 22 17.1323 21.143 18.8609 19.7165C18.9276 19.6614 18.9936 19.6055 19.0588 19.5488ZM12.7579 19.9959C12.6723 19.9986 12.5863 20 12.5 20C12.4914 20 12.4827 20 12.4741 20C12.3937 19.9997 12.3135 19.9983 12.2337 19.9956C10.8914 19.9517 9.63273 19.5772 8.53655 18.9508C9.45181 17.7632 10.8882 17 12.5 17C14.1118 17 15.5482 17.7632 16.4634 18.9508C15.365 19.5785 14.1033 19.9533 12.7579 19.9959ZM18.0624 17.7498C16.7832 16.0781 14.7675 15 12.5 15C10.2325 15 8.2168 16.0781 6.93759 17.7498C5.43447 16.2953 4.5 14.2568 4.5 12C4.5 7.58172 8.08172 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12C20.5 14.2568 19.5655 16.2953 18.0624 17.7498Z'
          fill='#845D00'
        />
      </svg>
    ),
    iconHide: (
      <svg
        className='h-full w-full'
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5 12C13.6046 12 14.5 11.1046 14.5 10C14.5 8.89543 13.6046 8 12.5 8C11.3954 8 10.5 8.89543 10.5 10C10.5 11.1046 11.3954 12 12.5 12ZM12.5 14C14.7091 14 16.5 12.2091 16.5 10C16.5 7.79086 14.7091 6 12.5 6C10.2909 6 8.5 7.79086 8.5 10C8.5 12.2091 10.2909 14 12.5 14Z'
          fill='black'
          fillOpacity='0.45'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.0588 19.5488C21.1672 17.7154 22.5 15.0134 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 15.0134 3.83284 17.7154 5.94116 19.5488C7.69693 21.0756 9.99052 22 12.5 22C14.9162 22 17.1323 21.143 18.8609 19.7165C18.9276 19.6614 18.9936 19.6055 19.0588 19.5488ZM12.7579 19.9959C12.6723 19.9986 12.5863 20 12.5 20C12.4914 20 12.4827 20 12.4741 20C12.3937 19.9997 12.3135 19.9983 12.2337 19.9956C10.8914 19.9517 9.63273 19.5772 8.53655 18.9508C9.45181 17.7632 10.8882 17 12.5 17C14.1118 17 15.5482 17.7632 16.4634 18.9508C15.365 19.5785 14.1033 19.9533 12.7579 19.9959ZM18.0624 17.7498C16.7832 16.0781 14.7675 15 12.5 15C10.2325 15 8.2168 16.0781 6.93759 17.7498C5.43447 16.2953 4.5 14.2568 4.5 12C4.5 7.58172 8.08172 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12C20.5 14.2568 19.5655 16.2953 18.0624 17.7498Z'
          fill='black'
          fillOpacity='0.45'
        />
      </svg>
    ),
    link: '/account/info',
    paths: [
      '/account/profile',
      '/account/info',
      '/account/referral',
      '/account/history-bet',
      '/account/history-payment',
      '/account/banking',
      '/account/agent',
      '/account/giftCode',
    ],
  },
];

const FooterMobile = () => {
  // const token = getCookie('token');
  const pathname = usePathname();
  const params = useParams();
  const lng = (params?.lng as string) || '';
  const { t } = useTranslation(lng, 'HomePage');
  const [open, setOpen] = useState(false);

  return (
    <>
      <footer className='fixed bottom-0 z-20 h-auto w-full md:hidden'>
        <div className='vw:h-[64px] shadow-3 absolute bottom-0 w-full bg-white'>
          <div
            className={clsx(
              'relative z-10 flex h-full flex-row items-center justify-between px-3',
              lng === fallbackLng && 'px-8'
            )}
          >
            <ButtonCore
              className='vw:!w-[65.5px] vw:!h-[64px] vw:-top-[30px] !absolute left-1/2 -translate-x-1/2 !rounded-full'
              type='ghost'
            >
              <Link
                href='/account/deposit'
                aria-label='navigate to deposit page or login'
              >
                <div className='vw:w-[65.5px] vw:h-[64px] vw:top-[0] absolute left-1/2 flex -translate-x-1/2 items-center justify-center'>
                  <motion.div
                    style={{
                      boxShadow:
                        '2px -4px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 2px 4px 0px #FFF inset',
                      filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))',
                    }}
                    className='bg-linear-8 vw:px-[15px] vw:py-[14px] flex items-center justify-center rounded-full'
                  >
                    <div className='light absolute h-[80%] w-[80%] rounded-full'></div>
                    <motion.svg
                      className='vw:w-[24px] vw:h-[24px]'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      initial={{ scale: 0, rotate: 180 }}
                      animate={{ scale: 1, rotate: 0 }}
                      transition={{
                        stiffness: 260,
                        damping: 20,
                        duration: 0.3,
                        ease: 'easeInOut',
                        repeat: Infinity,
                        repeatDelay: 10.5,
                      }}
                    >
                      <motion.path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M19.9998 6.17071V5C19.9998 3.34315 18.6567 2 16.9998 2H4.99985C3.34299 2 1.99985 3.34315 1.99985 5V19C1.99985 20.6569 3.34299 22 4.99985 22H18.9998C20.6567 22 21.9998 20.6569 21.9998 19V9C21.9998 7.69378 21.165 6.58254 19.9998 6.17071ZM3.99985 5C3.99985 4.44772 4.44756 4 4.99985 4H16.9998C17.5521 4 17.9998 4.44772 17.9998 5V6H4.99985C4.44756 6 3.99985 5.55228 3.99985 5ZM3.99985 7.82929C4.31263 7.93985 4.64921 8 4.99985 8H18.9998C19.5521 8 19.9998 8.44771 19.9998 9V11H16.9998C15.343 11 13.9998 12.3431 13.9998 14C13.9998 15.6569 15.343 17 16.9998 17H19.9998V19C19.9998 19.5523 19.5521 20 18.9998 20H4.99985C4.44756 20 3.99985 19.5523 3.99985 19V7.82929ZM16.9998 13H19.9998V15H16.9998C16.4476 15 15.9998 14.5523 15.9998 14C15.9998 13.4477 16.4476 13 16.9998 13Z'
                        fill='white'
                      />
                    </motion.svg>
                  </motion.div>
                </div>
              </Link>
            </ButtonCore>

            {Menus.map((_) => {
              const isActive = _.paths.includes(
                pathname.replace(`/${lng}`, '')
              );

              const Icon: any = isActive ? _?.icon : _?.iconHide;

              if (_.link === '/support') {
                return (
                  <div
                    onClick={() => {
                      setOpen(!open);
                    }}
                    key={_.label}
                    className='flex flex-col items-center justify-center'
                  >
                    <Modal
                      styles={{
                        mask: {
                          background: '#000000cc',
                        },
                      }}
                      // maskClassName='!bg-black !bg-opacity-80'
                      width={toVW(355)}
                      open={open}
                      footer={null}
                      title='Select Support'
                      onCancel={() => setOpen(false)}
                      className='!mt-[calc(50vh-145px)]'
                    >
                      <div className='flex items-center gap-3'>
                        <div className='w-1/2 rounded-2xl bg-[#3ACE01]'>
                          <a
                            target='_blank'
                            rel='noopener nofollow'
                            href='https://line.me/R/ti/p/@leo88support?from=page&amp;searchId=leo88support'
                          >
                            <div className='vw:p-3 flex flex-row items-center justify-center'>
                              <div className='mr-auto'>
                                <div className='vw:text-14 font-bold leading-[150%] text-black'>
                                  Line
                                </div>
                                <div className='vw:text-12 text-black text-opacity-50'>
                                  Support 24/7
                                </div>
                              </div>
                              <img
                                className='vw:h-10 vw:w-10'
                                src='/img/footer/support_2.png'
                              />
                            </div>
                          </a>
                        </div>
                        <div className='bg-main-pri w-1/2 rounded-2xl'>
                          <a
                            target='_blank'
                            rel='noopener nofollow'
                            href='https://direct.lc.chat/16386009/'
                          >
                            <div className='vw:p-3 flex flex-row items-center justify-center'>
                              <div className='mr-auto'>
                                <div className='vw:text-14 font-bold leading-[150%] text-black'>
                                  Live chat
                                </div>
                                <div className='vw:text-12 text-black text-opacity-50'>
                                  Support 24/7
                                </div>
                              </div>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='32'
                                height='32'
                                viewBox='0 0 32 32'
                                fill='none'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M7.99996 8.66664V10.6666H5.99996C4.15901 10.6666 2.66663 12.159 2.66663 14V27.8387C2.66663 29.5157 4.60649 30.4481 5.91602 29.4005L9.15068 26.8127C9.26889 26.7182 9.41577 26.6666 9.56715 26.6666H20.6666C22.5076 26.6666 24 25.1743 24 23.3333V21.3333H26C27.8409 21.3333 29.3333 19.8409 29.3333 18V3.73701C29.3333 2.13962 27.553 1.18683 26.2239 2.0729L21.5012 5.22134C21.3917 5.29435 21.2631 5.33331 21.1314 5.33331H11.3333C9.49234 5.33331 7.99996 6.82569 7.99996 8.66664ZM20.6666 10.6666H10.6666V8.66664C10.6666 8.29845 10.9651 7.99997 11.3333 7.99997H21.1314C21.7895 7.99997 22.4329 7.80518 22.9804 7.44014L26.6666 4.98268V18C26.6666 18.3682 26.3681 18.6666 26 18.6666H24V14C24 12.159 22.5076 10.6666 20.6666 10.6666ZM9.33329 17.3333C8.59691 17.3333 7.99996 17.9302 7.99996 18.6666C7.99996 19.403 8.59691 20 9.33329 20H17.3333C18.0697 20 18.6666 19.403 18.6666 18.6666C18.6666 17.9302 18.0697 17.3333 17.3333 17.3333H9.33329Z'
                                  fill='#FCFCFC'
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Modal>

                    <span className='vw:w-[24px] vw:h-[24px]  mx-auto flex  cursor-pointer'>
                      {Icon}
                    </span>
                    <span
                      className={clsx(
                        isActive && '!text-main-secondary ',
                        'vw:text-[10px] cursor-pointer text-center font-semibold leading-6 !text-black'
                      )}
                    >
                      {t(_.label)}
                    </span>
                  </div>
                );
              }
              return (
                <Link
                  prefetch={Boolean(_?.prefetch)}
                  href={`${_.link}`}
                  key={_.label}
                  className='flex flex-col items-center justify-center'
                  onClick={
                    typeof _.onClick === 'function' ? _.onClick : () => {}
                  }
                >
                  <span className='vw:w-[24px] vw:h-[24px] mx-auto flex cursor-pointer'>
                    {Icon}
                  </span>
                  <span
                    className={clsx(
                      isActive && '!text-main-secondary ',
                      'vw:text-[10px] cursor-pointer text-center font-semibold leading-6 !text-black'
                    )}
                  >
                    {t(_.label)}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterMobile;
